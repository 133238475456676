import { $speedP1, $speedP2, easeOutExpo, easeInOutExpo } from "../animations/animUtils"

/**
 * A series of helper functions, methods and variables used in the layout component
 *
 *  [1]. Session handler
 *  [2]. Zoom handler
 *  [3]. Scroll restoration handler
 *  [4]. Smooth scroll initialiser
 *  [5]. Add zoomHandler event listener
 *  [6]. Remove zoomHandler event listener
 *
 *
 */

/*
 
 
 
 
 
 
 
 
 
 */

// 1. Variants
// ==========================================================================
export const homeHeroFadeVars = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: $speedP1,
      ease: "linear",
      delay: /*window.sessionStorage.getItem("newSession") === null ? 4.1 :*/ 1,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: $speedP1,
      ease: "linear",
    },
  },
}

export const homeHeroverticalTagline = {
  initial: {
    x: "-150%",
  },

  animate: {
    x: "0%",
    transition: {
      duration: $speedP2,
      ease: easeOutExpo,
      delay: /*window.sessionStorage.getItem("newSession") === null ? 3.5 :*/ 1,
    },
  },
  exit: {
    x: "-150%",
    transition: { duration: $speedP2, ease: easeInOutExpo },
  },
}

// 2. Functions
// ==========================================================================
export const titleHoverIn = () => {
  const heroVideo = document.querySelector<HTMLElement>(".c-home-hero__bg-video")
  const heroSocialIcons = document.querySelector<HTMLElement>(".c-home-hero__social-icons")
  const heroVerticalTagline = document.querySelector<HTMLElement>(".o-vertical-tagline")
  const scrollPrompt = document.querySelector<HTMLElement>(".c-scroll-prompt")
  const projectButton = document.querySelector<HTMLElement>(".c-project-btn")
  const titleLines: Array<Element> = Array.from(document.getElementsByClassName("o-hero-title__text"))

  titleLines.forEach(el => {
    const htmlEl = el as HTMLElement
    htmlEl.style.color = "rgba(0,0,0,0)"
  })

  if (heroVideo) {
    heroVideo.classList.add("is-active")
  }

  if (heroSocialIcons) {
    heroSocialIcons.style.opacity = "0"
  }

  if (heroVerticalTagline) {
    heroVerticalTagline.style.opacity = "0"
  }

  if (scrollPrompt) {
    scrollPrompt.style.opacity = "0"
  }

  if (projectButton) {
    projectButton.style.visibility = "hidden"
  }
}

export const titleHoverOut = () => {
  const heroVideo = document.querySelector<HTMLElement>(".c-home-hero__bg-video")
  const heroSocialIcons = document.querySelector<HTMLElement>(".c-home-hero__social-icons")
  const heroVerticalTagline = document.querySelector<HTMLElement>(".o-vertical-tagline")
  const scrollPrompt = document.querySelector<HTMLElement>(".c-scroll-prompt")
  const projectButton = document.querySelector<HTMLElement>(".c-project-btn")
  const titleLines: Array<Element> = Array.from(document.getElementsByClassName("o-hero-title__text"))

  titleLines.forEach(el => {
    const htmlEl = el as HTMLElement
    htmlEl.style.color = "rgba(250, 250, 250, 1)"
  })

  if (heroVideo) {
    heroVideo.classList.remove("is-active")
  }

  if (heroSocialIcons) {
    heroSocialIcons.style.opacity = "1"
  }

  if (heroVerticalTagline) {
    heroVerticalTagline.style.opacity = "0.15"
  }

  if (scrollPrompt) {
    scrollPrompt.style.opacity = "1"
  }

  if (projectButton) {
    projectButton.style.visibility = "visible"
  }
}
