import * as React from "react"
import Image from "gatsby-image"

// Components
import { Container, Section } from "../StyledComponents/layoutComponentStyles"

// Styles
import "./_project-hero.scss"

// Animation
import { motion } from "framer-motion"
import { homeHeroverticalTagline } from "../../helpers/homeHeroHelpers"
import ScrollPrompt from "../ScrollPrompt"
import { delayedFade } from "../../animations/globalVariants"

interface ProjectHeroProps {
  project: any
}

/*









*/

const ProjectHero: React.FunctionComponent<ProjectHeroProps> = ({ project }) => {
  return (
    <Section fullHeight>
      <div className='hero-img__wrapper'>
        <Image fluid={project.heroImage.image.asset.fluid} alt={project.heroImage.altText} className='project-hero-img' />
        <Container sixteenClm project>
          <div className='o-vertical-tagline' id='project_hero_vertical_tagline'>
            <motion.span initial='initial' animate='animate' exit='exit' variants={homeHeroverticalTagline}>
              {project.title}
            </motion.span>
          </div>
          <ScrollPrompt variants={delayedFade} />
        </Container>
      </div>
    </Section>
  )
}

export default ProjectHero
