import * as React from "react"

// Styles
import "./_scroll-prompt.scss"

// Animations
import { motion, Variants } from "framer-motion"

// Interfaces
interface ScrollPromptProps {
  variants: Variants
}

/*









*/

const ScrollPrompt: React.FunctionComponent<ScrollPromptProps> = ({ variants }) => {
  return (
    <motion.div className='c-scroll-prompt' initial='initial' animate='animate' exit='exit' variants={variants}>
      <div className='c-scroll-prompt__inner'></div>
    </motion.div>
  )
}

export default ScrollPrompt
