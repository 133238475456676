import * as React from "react"
import { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import ReactGA from "react-ga"

// Context
import ScrollSafeContext from "../context/scrollSafeContext"

// Componenets
import SEO from "../components/seo"
import ProjectHero from "../components/ProjectHero"
import Modules from "../components/Modules"

// Animations
import { motion } from "framer-motion"
import { galleryScrub } from "../animations/pages/homeAnim"
import { $speedP2, easeInOutExpo, easeOutExpo } from "../animations/animUtils"
import { ScrollTrigger } from "gsap/ScrollTrigger"

// Variants
const pageWipe = {
  initial: { clipPath: typeof window !== `undefined` ? `inset(${window.innerHeight}px 0px 0px 0px)` : "inset(100vh 0px 0px 0px)" },
  animate: {
    clipPath: "inset(0px 0px 0px 0px)",
    transition: { duration: $speedP2, ease: easeInOutExpo, delay: 0.2 },
  },
  exit: {
    opacity: 0,
    transition: { duration: $speedP2, ease: easeOutExpo },
  },
}

// Get data from server
export const query = graphql`
  query($slug: String) {
    sanityProjects(slug: { current: { eq: $slug } }) {
      comingSoon
      featured
      title
      tagline
      slug {
        current
      }
      publishedAt
      projectCategories {
        title
      }
      thumbnail {
        altText
        image {
          asset {
            fluid(maxWidth: 2000) {
              ...GatsbySanityImageFluid
            }
            extension
            url
          }
        }
      }
      heroImage {
        altText
        image {
          asset {
            fluid(maxWidth: 3000) {
              ...GatsbySanityImageFluid
            }
            extension
            url
          }
        }
      }
      moduleContent {
        ... on SanityImageCarousel {
          _key
          _type
          images {
            altText
            image {
              asset {
                fluid(maxWidth: 3000) {
                  ...GatsbySanityImageFluid
                }
                extension
                url
              }
            }
          }
        }
        ... on SanityFullscreenImage {
          _key
          _type
          image {
            altText
            image {
              asset {
                fluid(maxWidth: 3000) {
                  ...GatsbySanityImageFluid
                }
                extension
                url
              }
            }
          }
        }
        ... on SanityImageGrid {
          _key
          _type
          images {
            _key
            altText
            image {
              asset {
                fluid(maxWidth: 3000) {
                  ...GatsbySanityImageFluid
                }
                extension
                url
              }
            }
          }
        }
        ... on SanityInsetImage {
          _key
          _type
          image {
            altText
            image {
              asset {
                fluid(maxWidth: 3000) {
                  ...GatsbySanityImageFluid
                }
                extension
                url
              }
            }
          }
        }
        ... on SanityProjectBanner {
          _key
          _type
          _rawBannerContent
          bannerTitle
        }
        ... on SanityProjectHeroInfo {
          _key
          _type
          _rawDescription
          client
          dateCompleted(formatString: "MMM YYYY")
          location
          services {
            title
          }
        }
        ... on SanityTestimonial {
          _key
          _type
          testimonialCompany
          testimonialName
          testimonialQuote
        }
        ... on SanityVideo {
          _key
          _type
          asset {
            url
          }
        }
      }
    }
  }
`

/*









*/

const Project: React.FunctionComponent = ({ data }: any) => {
  // Context
  const { scrollSafe, setScrollSafe } = useContext(ScrollSafeContext)

  // Effects
  useEffect(() => {
    if (scrollSafe) {
      const images: Array<HTMLElement> = Array.from(
        document.querySelectorAll(
          ".project-img-full__asset, .project-img-contained__asset, .project-img-grid__asset, .c-post-video__asset"
        )
      )

      images.forEach(img => {
        return galleryScrub(img)
      })

      const fullImages: Array<HTMLElement> = Array.from(document.querySelectorAll(".project-img__full"))

      if (fullImages.length) {
        fullImages[fullImages.length - 1].classList.add("last")
      }
    }
  }, [scrollSafe])

  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh()
      setScrollSafe?.(true)
    }, 450)

    ReactGA.pageview(window.location.pathname + window.location.search)

    return () => {
      setScrollSafe?.(false)
    }
  }, [])

  return (
    <>
      <SEO title={data.sanityProjects.title} />
      {scrollSafe && (
        <motion.div id='wipe-container' initial='initial' animate='animate' exit='exit' variants={pageWipe}>
          <div style={{ minHeight: "100%" }}>
            <ProjectHero project={data.sanityProjects} />

            {data.sanityProjects.moduleContent.map((module: any) => (
              <Modules project={data.sanityProjects} module={module} key={module._key} />
            ))}
          </div>
        </motion.div>
      )}
    </>
  )
}

export default Project

// <motion.div initial='initial' animate='animate' exit='exit' variants={pageWipe} id='wipe-container'>
// <div style={{ minHeight: "100%" }}>

//   <EmblaConstraintCarousel slides={project.imageCarousel} />
// </div>
// </motion.div>
